import router from './router'

router.beforeEach(async (to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	} else {
		document.title = ''
	}
	next()
	// 返回页面顶端
	window.scrollTo(0, 0)
})