import axios from 'axios'
import { Message } from 'element-ui'

// axios.defaults.baseURL = 'http://192.168.0.108:6633/front/'
axios.defaults.baseURL = 'http://jieranjiancai.com:6633/front/'

axios.interceptors.request.use(
    function (config) {
        config.headers['Authorization'] = 'Bearer 12815885656'
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    function (response) {
        if(response.data.code == 200) {
            return response
        } else {
            Message.error(response.data.msg || '请求失败');
            return Promise.reject('请求失败')
        }
    },
    function (error) {
        Message.error(error.message || '请求失败');
        return Promise.reject(error)
    }
)

export default axios
