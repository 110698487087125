export default [{
		name: '首页',
		path: '/'
	},
	{
		name: '关于洁然',
		path: '/about'
	},
	{
		name: '产品中心',
		path: '/product',
		children: [
			// {
			// 	name: '企业介绍',
			// 	path: '/product?id=11'
			// },
			// {
			// 	name: '品牌介绍',
			// 	path: '/product?id=12'
			// },
			// {
			// 	name: '企业文化',
			// 	path: '/product?id=122'
			// }
		]
	},
	{
		name: '招商合作',
		path: '/recruitment'
	},
	{
		name: '新闻资讯',
		path: '/news'
	},
	{
		name: '案例展示',
		path: '/case'
	},
	{
		name: '联系我们',
		path: '/contact'
	},
]