import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import './assets/css/main.css'
import Breadcrumb from '@/components/Breadcrumb/index'
import './permission'

Vue.use(ElementUI);
Vue.component('c-breadcrumb', Breadcrumb)

Vue.config.productionTip = false

const clickOutside = {
	bind(el, binding) {
		el.clickOutsideEvent = function (event) {
			if (!(el === event.target || el.contains(event.target))) {
				binding.value(event);
			}
		};
		document.addEventListener("click", el.clickOutsideEvent);
	},
	unbind(el) {
		document.removeEventListener("click", el.clickOutsideEvent);
	},
};

Vue.directive('click-outside', clickOutside)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
