<template>
	<div>
		<div class="header-lay">
			<div class="header-main flex_acjs">
				<img class="logo-img" :src="config.logo || '@/assets/images/top-logo.png'" alt="">
				<div class="header-content flex_ac">
					<div class="menu-item" :class="index == current && 'menu-item-av'" v-for="(item, index) in menuList"
						:key="index" @click="onMenu(index, -1)" :data-index="index" @mouseenter="handleMouseEnter"
						@mouseleave="handleMouseLeave" @mousemove="handleMouseMove">
						{{item.name}}
						<div class="menu-children" v-if="item.children?.length && index == handle">
							<div class="children-item" v-for="(items, indexs) in item.children" :key="indexs"  @click.stop="onMenu(index, indexs)">
								{{items.name}}</div>
						</div>
					</div>
				</div>
				<div class="header-right flex_ac">
					<div class="right-links" v-if="!isSelect" @mouseenter="isCode = true" @mouseleave="isCode = false">
						<img class="right-icon" src="@/assets/images/_1.png" alt="" v-if="isCode">
						<img class="right-icon" src="@/assets/images/1.png" alt="" v-else>
						<div class="link-popup" v-if="isCode">
							<img class="link-icon" :src="config.links?.imgUrl" alt="">
							<div class="link-title">手机淘宝扫一扫打开店铺</div>
							<div class="link-btn" @click="onUrl(config.links.storeUrl)">直接打开网页端店铺</div>
						</div>
					</div>
					<div class="right-phone" v-if="!isSelect">咨询热线：{{config.phone}}</div>
					<div class="select-lay flex_acjs" v-if="isSelect" v-click-outside="handleBlur">
						<input class="select-input" type="text" placeholder="搜索" v-model="selectValue" @keyup.enter="onSelect" ref="selectInput" />
						<img class="select-icon" src="@/assets/images/3.png" alt="" @click="onSelect">
					</div>
					<div class="select-icon" v-else @click="showSelect">
						<img class="select-icon" src="@/assets/images/3.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="header-base"></div>
	</div>
</template>

<script>
	import Routers from '@/libs/routers'
	import { head } from '@/api/api'
	export default {
		name: 'LayoutHeader',
		data() {
			return {
				current: -1,
				handle: -1,
				menuList: Routers,
				
				isCode: false,
				isSelect: false,
				selectValue: '',

				config: {}
			}
		},
		watch:{
			'$route'(to, from) {
				this.upCurrent()
			}
		},
		mounted() {
			this.getConfig()
		},
		methods: {
			async getConfig() {
				const { data } = await head()
				this.config = data
				if(this.config.links.length) this.config.links = data.links[0]
				this.menuList = this.menuList.map(item => {
					if(item.path == '/product') {
						item.children = this.config.cate.map(items => {
							return {
								path: '/product?id=' + items.cateId,
								...items
							}
						})
					}
					return item
				})
			},
			onMenu(index, indexs) {
				let d = this.menuList[index]
				if(indexs > -1) d = d.children[indexs]
				if(d.children?.length) return false
				this.current = index
				this.$router.push(d.path)
			},
			handleMouseEnter(event) {
				const targetElement = event.target
				const index = targetElement.getAttribute('data-index')
				this.handle = index
			},
			handleMouseLeave(event) {
				const targetElement = event.target;
				const index = targetElement.getAttribute('data-index')
				if (this.handle == index) {
					this.handle = -1
				}
			},
			handleMouseMove(event) {
				const targetElement = event.target
			},
			upCurrent() {
				setTimeout(() => {
					let index = this.menuList.findIndex(item => item.path == this.$route.path)
					this.current = index
				}, 100)
			},
			showSelect() {
				this.isSelect = !this.isSelect
				if(this.isSelect) {
					this.$nextTick(() => {
						this.$refs.selectInput.focus()
					})
				}
			},
			onSelect() {
				this.$router.push(`/select?select=${this.selectValue}`)
				this.selectValue = ''
				this.isSelect = false
			},
			handleBlur(event) {
			    this.selectValue = ''
			    this.isSelect = false
			},
			onUrl(url) {
				window.open(url)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.header-base {
		height: 100px;
	}

	.header-lay {
		width: 100%;
		height: 100px;
		background: #F6F9F6;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;

		.header-main {
			width: 1518px;
			height: 100px;
			margin: 0 auto;

			.logo-img {
				width: 129px;
				height: 54px;
			}

			.header-content {
				height: 100px;

				.menu-item {
					width: 130px;
					text-align: center;
					font-size: 16px;
					color: #333333;
					line-height: 100px;
					position: relative;
					cursor: pointer;

					.menu-children {
						position: absolute;
						right: 0;
						left: 0;
						margin: 0 auto;
						top: 100px;
						width: 130px;
						background: #F6F9F6;
						box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.16);
						border-radius: 10px;

						.children-item {
							width: 100%;
							height: 32px;
							text-align: center;
							line-height: 32px;
							font-size: 16px;
							color: #333333;
							cursor: pointer;
							font-weight: initial;
							padding: 0 14px;

							&:hover {
								background: rgba(0, 155, 76, 0.15);
							}
						}
					}
				}

				.menu-item-av {
					font-size: 18px;
					font-weight: bold;
				}
			}

			.header-right {
				.right-links{
					width: 32px;
					height: 32px;
					position: relative;

					.right-icon {
						width: 32px;
						height: 32px;
						cursor: pointer;
					}

					.link-popup{
						position: absolute;
						width: 177px;
						height: 232px;
						background: #FFFFFF;
						border-radius: 10px;
						left: -73px;
						top: 32px;
						padding-top: 17px;

						.link-icon{
							width: 129px;
							height: 131px;
							margin: 0 auto;
						}

						.link-title{
							font-size: 14px;
							color: #009944;
							padding-top: 11px;
							text-align: center;
						}

						.link-btn{
							width: 162px;
							height: 30px;
							background: rgba(255,91,1,0.1);
							border-radius: 15px;
							margin: 0 auto;
							margin-top: 11px;
							font-size: 14px;
							color: #FF5A00;
							text-align: center;
							line-height: 30px;
							cursor: pointer;
						}
					}
				}

				.right-phone {
					font-weight: bold;
					white-space:nowrap;
					font-size: 18px;
					color: #009944;
					padding: 0 29px;
				}

				.select-icon {
					width: 25px;
					height: 26px;
					cursor: pointer;
					transition: width 0.3s ease;
				}
				
				.select-lay{
					width: 215px;
					height: 45px;
					background: #FFFFFF;
					border-radius: 23px;
					border: 2px solid #009944;
					padding-right: 15px;
					transition: width 0.3s ease;
					
					.select-input{
						all: unset;
						width: 120px;
						font-size: 16px;
						color: #333333;
						padding: 0 15px;
					}
				}
			}
		}
	}
</style>