<template>
	<div class="breadcrumb-lay">
		当前位置：
		<span class="breadcrumb-item" v-for="(item, index) in bList" :key="index" @click="goPath(index)">
			{{item.name}} <span v-if="bList.length != index +1">></span>
		</span>
	</div>
</template>

<script>
	import Routers from '@/libs/routers'
	export default {
		name: 'CBreadcrumb',
		props: {
			list: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				
			}
		},
		computed: {
			bList() {
				if(this.list.length) {
					return this.list.map((item, index) =>{
						if(index + 1 == this.list.length) {
							return { name: item, path: '' }
						} else {
							let p = Routers.find(d => d.path == item)
							if(p.name) {
								return { name: p.name, path: p.path }
							} else {
								return { name: '', path:  ''}
							}
						}
					})
				} else {
					let home = Routers.find(d => d.path == '/' || '/index')
					let curr = Routers.find(d => d.path == this.$route.path)
					return [
						{ name: home.name, path: home.path },
						{ name: curr.name, path: '' }
					]
				}
			}
		},
		methods: {
			goPath(index) {
				let path = this.bList[index].path || ''
				if(path) this.$router.push(path)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.breadcrumb-lay{
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #333333;
		
		.breadcrumb-item{
			cursor: pointer;
			
			&:last-child{
				color: #009944;
			}
		}
	}
</style>