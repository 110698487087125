import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'IndexView',
		meta: {
			title: '首页'
		},
		component: () => import('../views/index/index.vue')
	},
	{
		path: '/about',
		name: 'AboutView',
		meta: {
			title: '关于洁然'
		},
		component: () => import('../views/about/index.vue')
	},
	{
		path: '/product',
		name: 'ProductView',
		meta: {
			title: '产品中心'
		},
		component: () => import('../views/product/index.vue')
	},
	{
		path: '/product/details',
		name: 'ProductDetailsView',
		meta: {
			title: '产品详情'
		},
		component: () => import('../views/product/details.vue')
	},
	{
		path: '/recruitment',
		name: 'RecruitmentView',
		meta: {
			title: '招商合作'
		},
		component: () => import('../views/recruitment/index.vue')
	},
	{
		path: '/news',
		name: 'NewsView',
		meta: {
			title: '新闻资讯'
		},
		component: () => import('../views/news/index.vue')
	},
	{
		path: '/news/details',
		name: 'newsDetailsView',
		meta: {
			title: '资讯详情'
		},
		component: () => import('../views/news/details.vue')
	},
	{
		path: '/case',
		name: 'CaseView',
		meta: {
			title: '案例展示'
		},
		component: () => import('../views/case/index.vue')
	},
	{
		path: '/case/details',
		name: 'CaseDetailsView',
		meta: {
			title: '案例详情'
		},
		component: () => import('../views/case/details.vue')
	},
	{
		path: '/contact',
		name: 'ContactView',
		meta: {
			title: '联系我们'
		},
		component: () => import('../views/contact/index.vue')
	},
	{
		path: '/select',
		name: 'SelectView',
		meta: {
			title: '搜索'
		},
		component: () => import('../views/select/index.vue')
	}
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	routes
})

export default router