<template>
	<div>
		<div class="footer-lay">
			<div class="footer-main">
				<div class="flex_acjs">
					<div>
						<img class="logo-img" :src="config.logo || '@/assets/images/top-logo.png'" alt="">
						<div class="menu-lay flex_ac">
							<div class="menu-item" v-for="(item, index) in menuList" :key="index" v-show="item.path !='/product'"
								@click="$router.push(item.path)">{{item.name}}</div>
						</div>
						<div class="footer-addres flex_ac">
							<div class="addres-text">
								公司地址：<br /><br />
								{{config.companyAddress}}
							</div>
							<div class="addres-text">
								门店地址：<br /><br />
								{{config.doorAddress}}
							</div>
						</div>
						<div class="footer-phone">
							咨询热线：
							<div class="span">{{config.phone}}</div>
						</div>
					</div>
					<div class="main-right">
						<img class="right-code" :src="config.WxAccount?.url" alt="">
						<div class="code-name">微信公众号</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-la2">
			<div class="footer-mai2 flex_acjs">
				<div class="mai2-left">{{config.copyright}}</div>
				<div class="mai2-right">友情链接：<span class="span" v-for="(item, index) in config.links" :key="index" @click="onUrl(item.url)">{{item.title}}</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Routers from '@/libs/routers'
	import { bottom } from '@/api/api'
	export default {
		name: 'LayoutFooter',
		data() {
			return {
				menuList: Routers,
				config: {}
			}
		},
		mounted() {
			this.getConfig()
		},
		methods: {
			async getConfig() {
				const { data } = await bottom()
				this.config = data
			},
			onUrl(url) {
				window.open(url)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.footer-lay {
		width: 100%;
		background: #032E16;

		.footer-main {
			width: 1437px;
			height: 381px;
			padding-top: 72px;
			margin: 0 auto;

			.logo-img {
				width: 111px;
				height: 47px;
			}

			.menu-lay {
				padding-top: 50px;

				.menu-item {
					font-size: 16px;
					color: #FFFFFF;
					margin-right: 80px;
					cursor: pointer;
				}
			}

			.footer-addres {
				padding-top: 45px;

				.addres-text {
					font-weight: 500;
					font-size: 14px;
					color: #FFFFFF;
					margin-right: 182px;
				}
			}

			.footer-phone {
				font-size: 14px;
				color: #F0BB34;
				padding-top: 20px;

				.span {
					font-size: 24px;
				}
			}

			.main-right {
				width: 148px;

				.right-code {
					width: 148px;
					height: 148px;
				}

				.code-name {
					font-size: 14px;
					color: #FFFFFF;
					padding-top: 16px;
					text-align: center;
				}
			}
		}
	}

	.footer-la2 {
		width: 100%;
		background: #032E16;
		border-top: 1px solid rgba($color: #FFFFFF, $alpha: 0.2);

		.footer-mai2 {
			width: 1437px;
			height: 79px;
			margin: 0 auto;

			.mai2-left {
				font-weight: 500;
				font-size: 16px;
				color: #FFFFFF;
			}

			.mai2-right {
				font-weight: 500;
				font-size: 16px;
				color: #FFFFFF;
				padding-right: 190px;
				cursor: pointer;

				.span {
					margin-right: 22px;
				}
			}
		}
	}
</style>