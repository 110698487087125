import request from '@/utils/request'

export function head(){
	return request.get('page/head')
}

export function bottom(){
	return request.get('page/bottom')
}

export function search(data){
	return request({
		method:'get',
		url:'page/search',
		params:data
	})
}

export function index(){
	return request.get('page/index')
}

export function about(){
	return request.get('page/about')
}

export function productConfig(data){
	return request({
		method:'get',
		url:'page/product',
		params:data
	})
}

export function productList(data){
	return request({
		method:'get',
		url:'product/cate',
		params:data
	})
}

export function productDetails(data){
	return request.get('product/' + data.id)
}

export function partnerConfig(){
	return request.get('page/partner')
}

export function partnerSubmit(data){
	return request.post('joinproxy', data)
}

export function newsConfig(){
	return request.get('page/news')
}

export function newsList(data){
	return request({
		method:'get',
		url:'article/jour',
		params:data
	})
}

export function newsDetails(data){
	return request.get('article/new/' + data.id)
}

export function caseConfig(){
	return request.get('page/case')
}

export function caseList(data){
	return request({
		method:'get',
		url:'article/case',
		params:data
	})
}

export function caseDetails(data){
	return request.get('article/case/' + data.id)
}

export function contactConfig(){
	return request.get('page/contact')
}
